<template>
  <div class=form>
    <form class="login-form">
      <div class="title">{{$t('login')}}</div>
      <div class="tab-nav cl">
        <div class="item" :class="{active:userType==='personal'}" @click="setTabNav('personal')">
          <i class="icons i-user"/>
          <span class="label">{{$t('PersonalUser')}}</span>
        </div>
        <div class="item" :class="{active:userType==='group'}" @click="setTabNav('group')">
          <i class="icons i-group"/>
          <span class="label">{{$t('GroupUser')}}</span>
        </div>
      </div>
      <div class="form-item">
        <div class="ipt">
          <input type="text"
                 :placeholder="$t('Please enter your email address/mobile number')"
                 autocomplete="off"
                 v-model.trim="id"
                 @keyup.enter="onLogin('email')"/>
        </div>
      </div>
      <p class="p1">{{$t('NoAccountYet')}}
        <router-link :to="registeredUrl">{{$t('RegisterNow')}}</router-link>
      </p>
      <div class="form-item">
        <div class="ipt">
          <input :type="showPassword"
                 :placeholder="$t('Please enter your password')"
                 autocomplete="off"
                 v-model.trim="accessToken"
                 @keyup.enter="onLogin('email')"/>
          <a class="btn-showpwd" href="javascript:;" @click="setInputPwd">
            <img src="../../assets/images/icon/eye.png" v-if="showPassword==='password'"/>
            <img src="../../assets/images/icon/eye-closed.png" v-else/>
          </a>
        </div>
      </div>
      <p class="p1">{{$t('ForgetPassword')}}
        <router-link :to="{name:'ForgetPassword'}">{{$t('RetrievePassword')}}</router-link>
      </p>
      <a class="btn-submit" href="javascript:;" :disabled="disableSubmit" @click="onLogin('email')">{{$t('login')}}</a>
      <ThirdParty @login="onLogin" />
    </form>
  </div>
</template>
<script>
  import ThirdParty from './components/ThirdParty';

  export default {
    name: 'Login',
    data() {
      return {
        userType: 'personal',
        showPassword: 'password',
        id: '',
        accessToken: '',
        redirect: ''
      };
    },
    computed: {
      disableSubmit: function () {
        return !this.id || !this.accessToken;
      },
      registeredUrl: function () {
        return this.userType === 'group' ? {name: 'RegisteredGroup'} : {name: 'Registered'};
      }
    },
    components: {ThirdParty},
    mounted() {
      this.redirect = this.$route.query.redirect || '';
      document.querySelector('html').classList.add('login-main');

      if (this.$route.query.invalidToken === '1') {
        this.$message.error('帳戶閒置或出現重覆登入，請再重新登入！');
      }
    },
    destroyed() {
      document.querySelector('html').classList.remove('login-main');
    },
    methods: {
      setTabNav(type) {
        this.userType = type;
      },
      setInputPwd() {
        this.showPassword = this.showPassword === 'password' ? 'text' : 'password';
      },
      onLogin(loginType, id, accessToken) {
        let data;
        if (loginType === 'google') {
          data = {loginType: 'google', id: id, accessToken: accessToken};
        } else if (loginType === 'facebook') {
          data = {loginType: 'facebook', id: id, accessToken: accessToken};
        } else if (loginType === 'email' && this.id && this.accessToken) {
          data = {loginType: 'email', id: this.id, accessToken: this.accessToken};
        } else if (loginType === 'wechat' && id && accessToken) {
          data = {loginType: 'wechat', id: id, accessToken: accessToken};
        }
        const loading = this.$loading({text: 'Loading'});
          this.$store.dispatch('user/login', data).then(() => {
            this.$store.dispatch('user/getInfo').then(() => {
              loading.close();
              this.$router.replace(this.redirect ? {path: this.redirect} : {name: 'My'});
            });
          }).catch(() => {
            loading.close();
          });
      }
    }
  };
</script>
<style scoped lang="less">
.form{max-width: 100%; }
  @import "style";
</style>
